<script lang="ts" setup></script>
<template>
    <div class="section">
        <div class="column is-6 is-offset-3">
            <div class="loader-wrapper is-active">
                <div class="loader is-loading"></div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
@import '../../assets/Scss/colors.scss';

.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: 0;
    z-index: -1;
    transition: opacity .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    .loader {
        height: 80px;
        width: 80px;
    }

    &.is-active {
        opacity: 1;
        z-index: 1;
    }
}

.button.is-loading::after,
.loader,
.select.is-loading::after,
.control.is-loading::after {
    -webkit-animation: spinAround 500ms infinite linear;
    animation: spinAround 500ms infinite linear;
    border: 6px solid $blucriluma;
    border-radius: 9999px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1em;
    position: relative;
    width: 1em;
}
</style>